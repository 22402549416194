import classnames from 'classnames';

export type Props = {
  className?: string;
  noPadding?: boolean;
  children: React.ReactNode;
  onClick?: (e: Event) => void;
} & JSX.IntrinsicElements['button'];

function Button({className, noPadding = false, ...props}: Props) {
  return <button {...props} className={classnames('text-sm font-medium rounded', noPadding || 'p-3', className)} />;
}

export default Button;
